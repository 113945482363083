import SvgIcon, { SvgIconProps } from "./SvgIcon";

const CoinsHand: React.FC<SvgIconProps> = ({ color = "#1F2A37", ...props }) => {
  return (
    <SvgIcon width={21} height={21} color="none" viewBox="0 0 21 21" {...props}>
      <path
        d="M14.2499 2.46676C12.1789 2.46676 10.4999 4.14569 10.4999 6.21676C10.4999 8.28782 12.1789 9.96676 14.2499 9.96676C16.321 9.96676 17.9999 8.28782 17.9999 6.21676C17.9999 4.14569 16.321 2.46676 14.2499 2.46676Z"
        fill={color}
      />
      <path
        d="M10.5264 2.2828C9.4837 3.27008 8.83325 4.66746 8.83325 6.21676C8.83325 7.21433 9.10293 8.14893 9.57335 8.95161C9.20901 9.06964 8.82025 9.13342 8.41659 9.13342C6.34552 9.13342 4.66659 7.45449 4.66659 5.38342C4.66659 3.31235 6.34552 1.63342 8.41659 1.63342C9.19894 1.63342 9.92534 1.87301 10.5264 2.2828Z"
        fill={color}
      />
      <path
        d="M15.2573 14.0445C15.2573 14.7039 14.8212 15.2616 14.2199 15.448L14.6065 15.4301C15.029 15.4301 15.4398 15.2972 15.7774 15.0505L18.1348 13.3306C18.7232 12.9015 19.5467 12.9585 20.0668 13.4644C20.6444 14.0263 20.6444 14.9366 20.0668 15.4975L18.0441 17.4651C17.4996 17.9948 16.8049 18.3564 16.0496 18.5035L13.1907 19.0597C12.6238 19.1698 12.0393 19.1566 11.4783 19.0199L8.89258 18.3915C8.58327 18.3156 8.26615 18.2776 7.94708 18.2776C7.60834 18.2776 7.43896 18.2776 7.30307 18.2291C7.0677 18.1451 6.88246 17.9599 6.79844 17.7245C6.74992 17.5886 6.74992 17.4192 6.74992 17.0805V14.2228C6.74992 13.9847 6.74992 13.8656 6.78051 13.7558C6.8076 13.6586 6.85213 13.5671 6.91192 13.4858C6.97943 13.394 7.07315 13.3205 7.26059 13.1736L8.24859 12.3989C8.24859 12.3989 9.88588 11.6334 10.7904 11.6334C11.1651 11.6334 11.5378 11.679 11.9018 11.7693L14.1382 12.3252C14.7958 12.4882 15.2573 13.0762 15.2573 13.7505V14.0445Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.39453 11.6334C3.40188 11.6334 3.40923 11.6334 3.41659 11.6334C3.42395 11.6334 3.4313 11.6334 3.43865 11.6334C3.61369 11.6334 3.78421 11.6334 3.92928 11.6433C4.08792 11.6541 4.27603 11.6795 4.47106 11.7603C4.87944 11.9294 5.2039 12.2539 5.37305 12.6623C5.45384 12.8573 5.47924 13.0454 5.49006 13.2041C5.49996 13.3491 5.49994 13.5196 5.49992 13.6947C5.49992 13.702 5.49992 13.7094 5.49992 13.7167L5.49992 17.9055C5.49994 18.0805 5.49996 18.251 5.49006 18.3961C5.47924 18.5547 5.45384 18.7428 5.37305 18.9379C5.2039 19.3463 4.87944 19.6707 4.47106 19.8399C4.27602 19.9207 4.08792 19.9461 3.92928 19.9569C3.78421 19.9668 3.61369 19.9668 3.43865 19.9667H3.39452C3.21949 19.9668 3.04897 19.9668 2.9039 19.9569C2.74525 19.9461 2.55715 19.9207 2.36212 19.8399C1.95374 19.6707 1.62928 19.3463 1.46012 18.9379C1.37934 18.7428 1.35394 18.5547 1.34312 18.3961C1.33322 18.251 1.33324 18.0805 1.33325 17.9055L1.33325 13.7167C1.33325 13.7094 1.33325 13.702 1.33325 13.6947C1.33324 13.5196 1.33322 13.3491 1.34312 13.2041C1.35394 13.0454 1.37934 12.8573 1.46012 12.6623C1.62928 12.2539 1.95374 11.9294 2.36212 11.7603C2.55715 11.6795 2.74526 11.6541 2.9039 11.6433C3.04897 11.6334 3.21949 11.6334 3.39453 11.6334Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default CoinsHand;
