import SvgIcon, { SvgIconProps } from "./SvgIcon";

const Share: React.FC<SvgIconProps> = ({ color = "#1F2A37", ...props }) => {
  return (
    <SvgIcon width={21} height={21} color="none" viewBox="0 0 21 21" {...props}>
      <path
        d="M12.1667 4.96676C12.1667 3.12581 13.6591 1.63342 15.5001 1.63342C17.341 1.63342 18.8334 3.12581 18.8334 4.96676C18.8334 6.8077 17.341 8.30009 15.5001 8.30009C14.5739 8.30009 13.7359 7.92232 13.1318 7.31246L8.7087 9.89368C8.78995 10.1819 8.83342 10.4859 8.83342 10.8001C8.83342 11.1141 8.79 11.418 8.70883 11.706L13.1345 14.285C13.7384 13.6767 14.5753 13.3001 15.5001 13.3001C17.341 13.3001 18.8334 14.7925 18.8334 16.6334C18.8334 18.4744 17.341 19.9668 15.5001 19.9668C13.6591 19.9668 12.1667 18.4744 12.1667 16.6334C12.1667 16.3179 12.2106 16.0126 12.2925 15.7233L7.86871 13.1455C7.2646 13.7555 6.42647 14.1334 5.50008 14.1334C3.65913 14.1334 2.16675 12.641 2.16675 10.8001C2.16675 8.95914 3.65913 7.46676 5.50008 7.46676C6.42629 7.46676 7.26428 7.84452 7.86836 8.45438L12.2915 5.87315C12.2102 5.58496 12.1667 5.28093 12.1667 4.96676Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Share;
