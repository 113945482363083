"use client";
import SideNavigation from "@components/SideNavigation";
import { useAuth } from "@context/Auth";
import { useRouter } from "next/navigation";
import React, { useEffect } from "react";

const AuthenticatedLayout = ({
  children,
  modal,
}: {
  children: React.ReactNode;
  modal: React.ReactNode;
}) => {
  const { user, loading } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!loading && !user) {
      router.push(
        `/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`,
      );
    }
  }, [user, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <SideNavigation />
      <main className="ml-0 flex h-auto max-h-full flex-col px-4 py-2 sm:ml-sideBar-mobile sm:h-dynamic-screen sm:max-h-dynamic-screen sm:overflow-auto md:ml-sideBar">
        {children}
      </main>
      {modal}
    </>
  );
};

export default AuthenticatedLayout;
