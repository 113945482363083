import SvgIcon, { SvgIconProps } from "./SvgIcon";

const Login: React.FC<SvgIconProps> = ({ color = "#1F2A37", ...props }) => {
  return (
    <SvgIcon width={21} height={21} color="none" viewBox="0 0 21 21" {...props}>
      <path
        d="M6.72429 2.19748C6.5279 2.16638 6.30956 2.16652 6.05579 2.16668L5.9311 2.16669C5.53362 2.16656 5.23905 2.16646 4.97842 2.20774C3.55211 2.43365 2.43348 3.55228 2.20757 4.97859C2.16629 5.23922 2.16639 5.53379 2.16652 5.93127V15.0688C2.16639 15.4663 2.16629 15.7609 2.20757 16.0215C2.43348 17.4478 3.55211 18.5664 4.97842 18.7923C5.23903 18.8336 5.53359 18.8335 5.93103 18.8334H6.05579C6.30956 18.8336 6.5279 18.8337 6.72429 18.8026C7.79402 18.6332 8.63299 17.7942 8.80242 16.7245C8.83352 16.5281 8.83339 16.3097 8.83322 16.056V13.8333C8.83322 13.3731 8.46012 13 7.99989 13C6.61918 13 5.49989 11.8807 5.49989 10.5C5.49989 9.11928 6.61918 7.99999 7.99989 7.99999C8.46012 7.99999 8.83322 7.62689 8.83322 7.16665V4.94411C8.83339 4.69035 8.83352 4.47199 8.80242 4.27562C8.63299 3.20589 7.79402 2.36691 6.72429 2.19748Z"
        fill={color}
      />
      <path
        d="M12.7558 15.2559C12.4304 15.5814 11.9028 15.5814 11.5773 15.2559L7.41068 11.0893C7.08518 10.7638 7.08518 10.2362 7.41068 9.91081L11.5773 5.74411C11.9028 5.41861 12.4304 5.41861 12.7558 5.74411C13.0813 6.06951 13.0813 6.59722 12.7558 6.92262L10.0118 9.66672H17.9999C18.4601 9.66672 18.8332 10.0398 18.8332 10.5C18.8332 10.9602 18.4601 11.3334 17.9999 11.3334H10.0118L12.7558 14.0774C13.0813 14.4029 13.0813 14.9305 12.7558 15.2559Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Login;
