import SvgIcon, { SvgIconProps } from "./SvgIcon";

const Logout: React.FC<SvgIconProps> = ({ color = "#1F2A37", ...props }) => {
  return (
    <SvgIcon width={21} height={21} color="none" viewBox="0 0 21 21" {...props}>
      <path
        d="M6.72429 2.19748C6.5279 2.16638 6.30956 2.16652 6.05579 2.16668L5.9311 2.16669C5.53362 2.16656 5.23905 2.16646 4.97842 2.20774C3.55211 2.43365 2.43348 3.55228 2.20757 4.97859C2.16629 5.23922 2.16639 5.53379 2.16652 5.93127V15.0688C2.16639 15.4663 2.16629 15.7609 2.20757 16.0215C2.43348 17.4478 3.55211 18.5664 4.97842 18.7923C5.23903 18.8336 5.53359 18.8335 5.93103 18.8334L6.05579 18.8334C6.30956 18.8336 6.5279 18.8337 6.72429 18.8026C7.79402 18.6332 8.63299 17.7942 8.80242 16.7245C8.83352 16.5281 8.83339 16.3097 8.83322 16.056V13.8333C8.83322 13.3731 8.46012 13 7.99989 13C6.61918 13 5.49989 11.8807 5.49989 10.5C5.49989 9.11928 6.61918 7.99999 7.99989 7.99999C8.46012 7.99999 8.83322 7.62689 8.83322 7.16665V4.94411C8.83339 4.69035 8.83352 4.47199 8.80242 4.27562C8.63299 3.20589 7.79402 2.36691 6.72429 2.19748Z"
        fill={color}
      />
      <path
        d="M13.244 5.74407C13.5694 5.41863 14.097 5.41863 14.4225 5.74407L18.5891 9.91073C18.9146 10.2362 18.9146 10.7638 18.5891 11.0892L14.4225 15.2559C14.097 15.5814 13.5694 15.5814 13.244 15.2559C12.9185 14.9305 12.9185 14.4028 13.244 14.0774L15.988 11.3333H7.99989C7.53965 11.3333 7.16655 10.9602 7.16655 10.5C7.16655 10.0398 7.53965 9.66666 7.99989 9.66666H15.988L13.244 6.92258C12.9185 6.59714 12.9185 6.0695 13.244 5.74407Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Logout;
