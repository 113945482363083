import SvgIcon, { SvgIconProps } from "./SvgIcon";

const Transactions: React.FC<SvgIconProps> = ({
  color = "#1F2A37",
  ...props
}) => {
  return (
    <SvgIcon width={21} height={21} color="none" viewBox="0 0 60 60" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2323 8.23223C39.2085 7.25593 40.7915 7.25593 41.7677 8.23223L55.9142 22.3787C57.804 24.2686 56.4655 27.5 53.793 27.5H5C3.6193 27.5 2.5 26.3807 2.5 25C2.5 23.6193 3.6193 22.5 5 22.5H48.9645L38.2323 11.7678C37.256 10.7914 37.256 9.20855 38.2323 8.23223ZM11.0355 37.5H55C56.3807 37.5 57.5 36.3807 57.5 35C57.5 33.6193 56.3807 32.5 55 32.5H6.2071C3.5344 32.5 2.19589 35.7315 4.08578 37.6213L18.2322 51.7677C19.2086 52.744 20.7915 52.744 21.7678 51.7677C22.7441 50.7915 22.7441 49.2085 21.7678 48.2323L11.0355 37.5Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Transactions;
