import SvgIcon, { SvgIconProps } from "./SvgIcon";

const DefaultAvatar: React.FC<SvgIconProps> = ({
  color = "#1F2A37",
  ...props
}) => {
  return (
    <SvgIcon width={29} height={29} color="none" viewBox="0 0 29 29" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 28.5C22.2319 28.5 28.5 22.2319 28.5 14.5C28.5 6.76801 22.2319 0.5 14.5 0.5C6.76801 0.5 0.5 6.76801 0.5 14.5C0.5 22.2319 6.76801 28.5 14.5 28.5ZM18.7 11.7C18.7 14.0197 16.8197 15.9 14.5 15.9C12.1803 15.9 10.3 14.0197 10.3 11.7C10.3 9.38041 12.1803 7.5 14.5 7.5C16.8197 7.5 18.7 9.38041 18.7 11.7ZM14.5003 25.7C11.3665 25.7 8.5334 24.413 6.50058 22.3387C8.36502 20.107 11.1469 18.7 14.5003 18.7C17.8537 18.7 20.6355 20.107 22.5 22.3387C20.4672 24.413 17.634 25.7 14.5003 25.7Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default DefaultAvatar;
