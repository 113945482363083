import SvgIcon, { SvgIconProps } from "./SvgIcon";

const Stack: React.FC<SvgIconProps> = ({ color = "#1F2A37", ...props }) => {
  return (
    <SvgIcon width={21} height={21} color="none" viewBox="0 0 21 21" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2694 1.70405C10.4218 1.67547 10.5781 1.67547 10.7304 1.70405C10.9108 1.73789 11.0717 1.82 11.1494 1.85968C11.1574 1.86376 11.1646 1.8674 11.1707 1.87048L19.2059 5.88808C19.4882 6.02924 19.6666 6.31779 19.6666 6.63344C19.6666 6.94908 19.4882 7.23763 19.2059 7.37879L11.1707 11.3964C11.1646 11.3995 11.1574 11.4031 11.1494 11.4072C11.0717 11.4469 10.9108 11.529 10.7304 11.5628C10.5781 11.5914 10.4218 11.5914 10.2694 11.5628C10.0891 11.529 9.92815 11.4469 9.8504 11.4072C9.8424 11.4031 9.83527 11.3995 9.8291 11.3964L1.79391 7.37879C1.51159 7.23763 1.33325 6.94908 1.33325 6.63344C1.33325 6.31779 1.51159 6.02924 1.79391 5.88808L9.8291 1.87048C9.83527 1.8674 9.8424 1.86376 9.85041 1.85967C9.92815 1.82 10.0891 1.73789 10.2694 1.70405Z"
        fill={color}
      />
      <path
        d="M2.60633 9.64845L1.79391 10.0547C1.51159 10.1958 1.33325 10.4844 1.33325 10.8C1.33325 11.1157 1.51159 11.4042 1.79391 11.5454L9.8291 15.563L9.8504 15.5738C9.92815 15.6134 10.0891 15.6956 10.2694 15.7294C10.4218 15.758 10.5781 15.758 10.7304 15.7294C10.9108 15.6956 11.0717 15.6135 11.1494 15.5738L11.1707 15.563L19.2059 11.5454C19.4882 11.4042 19.6666 11.1157 19.6666 10.8C19.6666 10.4844 19.4882 10.1958 19.2059 10.0547L18.3935 9.64845L11.9161 12.8872L11.9063 12.8921C11.8656 12.9125 11.7655 12.9628 11.6653 13.0063C11.5317 13.0645 11.3153 13.1489 11.0377 13.201C10.6823 13.2676 10.3175 13.2676 9.96209 13.201C9.68449 13.1489 9.46812 13.0645 9.33449 13.0063C9.23434 12.9628 9.13429 12.9125 9.09358 12.8921L9.08374 12.8872L2.60633 9.64845Z"
        fill={color}
      />
      <path
        d="M2.60645 13.8151L1.79391 14.2213C1.51159 14.3625 1.33325 14.651 1.33325 14.9667C1.33325 15.2823 1.51159 15.5709 1.79391 15.712L9.8291 19.7296L9.8504 19.7404C9.92815 19.7801 10.0891 19.8622 10.2694 19.8961C10.4218 19.9246 10.5781 19.9246 10.7304 19.8961C10.9108 19.8622 11.0717 19.7801 11.1494 19.7404L11.1707 19.7296L19.2059 15.712C19.4882 15.5709 19.6666 15.2823 19.6666 14.9667C19.6666 14.651 19.4882 14.3625 19.2059 14.2213L18.3934 13.8151L11.9161 17.0537L11.9063 17.0586C11.8656 17.0791 11.7655 17.1293 11.6653 17.1729C11.5317 17.231 11.3153 17.3154 11.0377 17.3675C10.6823 17.4342 10.3175 17.4342 9.96209 17.3675C9.68449 17.3154 9.46812 17.231 9.33449 17.1729C9.23434 17.1293 9.13429 17.0791 9.09358 17.0586L9.08374 17.0537L2.60645 13.8151Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default Stack;
