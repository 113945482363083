import SvgIcon, { SvgIconProps } from "./SvgIcon";

const PresentationChart: React.FC<SvgIconProps> = ({
  color = "#1F2A37",
  ...props
}) => {
  return (
    <SvgIcon width={21} height={21} color="none" viewBox="0 0 21 21" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33325 3.30001C1.33325 2.83977 1.70635 2.46667 2.16659 2.46667H18.8333C19.2935 2.46667 19.6666 2.83977 19.6666 3.30001C19.6666 3.76025 19.2935 4.13334 18.8333 4.13334V10.1678C18.8333 10.8386 18.8333 11.3922 18.7964 11.8432C18.7582 12.3116 18.676 12.7422 18.4699 13.1466C18.1504 13.7739 17.6404 14.2838 17.0132 14.6034C16.6088 14.8094 16.1782 14.8916 15.7098 14.9298C15.2588 14.9667 14.7051 14.9667 14.0343 14.9667H12.8016L16.0334 17.6598C16.387 17.9545 16.4348 18.4799 16.1401 18.8335C15.8455 19.1871 15.32 19.2348 14.9665 18.9402L11.3333 15.9125V18.3C11.3333 18.7602 10.9602 19.1333 10.4999 19.1333C10.0397 19.1333 9.66658 18.7602 9.66658 18.3V15.9126L6.03343 18.9402C5.67986 19.2348 5.15439 19.1871 4.85976 18.8335C4.56512 18.4799 4.61289 17.9545 4.96645 17.6598L8.19823 14.9667H6.96552C6.29471 14.9667 5.74106 14.9667 5.29006 14.9298C4.82165 14.8916 4.39106 14.8094 3.98662 14.6034C3.35941 14.2838 2.84948 13.7739 2.5299 13.1466C2.32382 12.7422 2.24169 12.3116 2.20341 11.8432C2.16657 11.3922 2.16658 10.8386 2.16659 10.1678L2.16659 4.13334C1.70635 4.13334 1.33325 3.76025 1.33325 3.30001ZM14.6666 6.63334C14.6666 6.1731 14.2935 5.80001 13.8333 5.80001C13.373 5.80001 12.9999 6.1731 12.9999 6.63334V10.8C12.9999 11.2602 13.373 11.6333 13.8333 11.6333C14.2935 11.6333 14.6666 11.2602 14.6666 10.8V6.63334ZM10.4999 7.46668C10.9602 7.46668 11.3333 7.83977 11.3333 8.30001V10.8C11.3333 11.2602 10.9602 11.6333 10.4999 11.6333C10.0397 11.6333 9.66658 11.2602 9.66658 10.8V8.30001C9.66658 7.83977 10.0397 7.46668 10.4999 7.46668ZM7.16658 9.13334C7.62682 9.13334 7.99992 9.50644 7.99992 9.96668V10.8C7.99992 11.2602 7.62682 11.6333 7.16658 11.6333C6.70635 11.6333 6.33325 11.2602 6.33325 10.8V9.96668C6.33325 9.50644 6.70635 9.13334 7.16658 9.13334Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default PresentationChart;
